#clubDropdown.dropdown-toggle {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: white; 
  }
  
#clubDropdown .dropdown-item {
    color: white; 
}
  
#clubDropdown .dropdown-menu {
    background-color: transparent; 
    border: none;
}
  